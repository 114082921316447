exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-regulaminy-js": () => import("./../../../src/pages/regulaminy.js" /* webpackChunkName: "component---src-pages-regulaminy-js" */),
  "component---src-pages-rezerwacja-js": () => import("./../../../src/pages/rezerwacja.js" /* webpackChunkName: "component---src-pages-rezerwacja-js" */),
  "component---src-pages-trasy-splywow-js": () => import("./../../../src/pages/trasy-splywow.js" /* webpackChunkName: "component---src-pages-trasy-splywow-js" */),
  "component---src-templates-offer-template-js": () => import("./../../../src/templates/OfferTemplate.js" /* webpackChunkName: "component---src-templates-offer-template-js" */)
}

